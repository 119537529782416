import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import userContext from '../../context/userContext';
import ErrorNotice from '../../misc/ErrorNotice';
import './Register.css'
import url from '../../misc/url'
import { toast } from 'react-toastify';
import NewNav from '../Navbar/navbar_new'
import Footer from '../Footer/Footer'
import {AiFillGoogleCircle} from 'react-icons/ai'
import ReCAPTCHA from 'react-recaptcha';


const Register = () => {
    
    const[isVerified,setisVerified]=useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [enrollment, setEnrollment] = useState('');
    const [name, setName] = useState('');
    const [dob, setdob] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [error, setError] = useState('');
    const[upi,setUpi]=useState('');
    const [phoneNumber,setPhoneNumber]=useState('');

    const { setUserData } = useContext(userContext);

    const history = useHistory();

    function onChange(value) {
        console.log("Captcha value:", value);
        setisVerified(true);
      }

    const submit = async (e) => {
        e.preventDefault();
        try {
            const newUser = {  email, enrollment, name, password, passwordCheck, phoneNumber, upi }; 
            await axios.post(`${url.serverURL}/user/register`, {...newUser}).then(res => {
                console.log(res);
            });
           
            notifyUser();
            const loginResponse = await axios.post(`${url.serverURL}/user/login`, {
                email, password
            });
            console.log('loginResponse', loginResponse);
            setUserData({
                token: loginResponse.data.token,
                user: loginResponse.data.user
            });
            localStorage.setItem("auth-token", loginResponse.data.token);
            setUsername('');
            setEmail('');
            setEnrollment('');
            setName('');
            setPassword('');
            setPasswordCheck('');
            history.push("/");
        } catch (error) {
            if (error.response){
                if(error.response.data.msg!="No account with this email has been registered"){
                    error.response.data.msg && setError(error.response.data.msg)
                }
            }
        }
    };
    const notifyUser = () => {
        toast.info('Please check your mail', {position: toast.POSITION.TOP_CENTER});
    }

    return (
        <>
            <NewNav/>
            <div className="register-container" style={{marginTop:100}}>
                <div className="register-wrapper">
                    <div className="register-title">Sign Up</div>
                    <div className="form-error">
                        {error && <ErrorNotice message={error} clearError={() => setError(undefined)} />}
                    </div>
                    <form onSubmit={submit}>
                        {/* <div className="register-field">
                            <input type="text" id="username" name="username" onChange={e => setUsername(e.target.value)} value={username} required />
                            <label>XXX</label>
                        </div> */}
                        <div className="register-field">
                            <input type="text" id="email" name="email" onChange={e => setEmail(e.target.value)} value={email} required />
                            <label>email (use personal id)</label>
                        </div>
                        <div className="register-field">
                            <input type="text" id="name" name="name" onChange={e => setName(e.target.value)} value={name} required />
                            <label>name</label>
                        </div>
                        <div className="register-field">
                            <input type="tel" id="phone-number" name="phoneNumber" onChange={e => setPhoneNumber(e.target.value)} value={phoneNumber} required />
                            <label>phone</label>
                        </div>
                        <div className="register-field">
                            <input type="text" id="enrollment" name="enrollment" onChange={e => setEnrollment(e.target.value)} value={enrollment} required />
                            <label>enrollment no./ faculty id</label>
                        </div>
                        {/* <div className="register-field">
                            <input type="date" id="dob" name="dob" onChange={e => setdob(e.target.value)} value={dob} required />
                            <label id='dob-label'>Date of Birth</label>
                        </div> */}
                        {/* <div className="register-field">
                            <input type="text" id="upi-id" name="upi" onChange={e => setUpi(e.target.value)} value={upi} required />
                            <label>UPI Id</label>
                        </div> */}
                        <div className="register-field">
                            <input type="password" id="password" name="password" onChange={e => setPassword(e.target.value)} value={password} required />
                            <label>password</label>
                        </div>
                        <div className="register-field">
                            <input type="password" id="checkPassword" name="passwordCheck" onChange={e => setPasswordCheck(e.target.value)} value={passwordCheck} required />
                            <label>confirm password</label>
                        </div>
                        <div style={{marginTop:"20px"}}>
                        {/* <ReCAPTCHA
                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                onChange={onChange}
                            /> */}
                        <div className="register-field" disabled={isVerified}>
                            <input type="submit" value={ `Sign Up`}></input>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>

        </>
    )
}

export default Register
