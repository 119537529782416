import React, { useContext, useEffect, useState } from 'react'
import NewNav from '../Navbar/navbar_new'
import './BookPage.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import GetBookModal from './GetBookModal';
import userContext from '../../context/userContext';
import url from '../../misc/url';
import Footer from '../Footer/Footer';
import book_pic from "./Book_cover.jpg"
import confirmation from './Conformation';

const BookPage = () => {
    const [name, setName] = useState('');
    const [author, setAuthor] = useState('');
    const [desc, setDesc] = useState('');
    const [price, setPrice] = useState();
    const [image, setImage] = useState('');
    const [available, setAvailable] = useState('true');
    const [lendingPrice, setLendingPrice] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [returnDate, setReturnDate] = useState('');
    const { id } = useParams();

    const { userData, setUserData } = useContext(userContext);

    useEffect(() => {
        console.log(id);
        axios.get(`${url.serverURL}/books/detail/${id}`)
            .then((res) => {
                console.log(res.data);
                setName(res.data.book);
                setAuthor(res.data.author);
                setPrice(res.data.price);
                setDesc(res.data.desc);
                setImage(res.data.bookImage);
                setAvailable(res.data.available);
                setReturnDate(res.data.returnDate);
                // console.log(available);
                if (res.data.price <= 200) {
                    setLendingPrice(10);
                } else if (res.data.price <= 500) {
                    setLendingPrice(15);
                } else if (res.data.price <= 700) {
                    setLendingPrice(20);
                } else if (res.data.price <= 1000) {
                    setLendingPrice(25);
                } else {
                    setLendingPrice(30);
                }
            })
    }, [userData])

    function rentPrice() {
        if (price >= 3000) { return 39; }
        else if (price >= 1000) { return 29; }
        else if (price >= 500) { return 24; }
        else if (price >= 200) { return 19; }
        else { return 14; }
    }

    return (
        <>
            <NewNav />
            <div className="bookpage">
                <div className="bookpage-book-name">
                    {name}
                </div>
                <div className="bookpage-flex">
                    <div className="bookpage-image">
                        <img src={`${url.serverURL}/uploads/${id}.jpg`} />
                    </div>
                    <div className="bookpage-details">
                        <div className="bookpage-author-name">
                            - {author}
                        </div>
                        <div className="bookpage-short-desc">
                            About book:
                            <div>
                                {desc}
                            </div>
                        </div>
                        <div className="owner-flex">
                            <div>
                                <div className="bookpage-market-price">
                                    Market Price:
                                    <div>
                                        Rs. {price}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="bookpage-owner-name">
                                    Lending Price:
                                    <div>
                                        Rs. {rentPrice(() => { })} /week
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {console.log(available)}
                {available ?
                    (<>
                    {userData.user ? (
                        <>
                            <div className="button-flex">
                                <button className="bookpage-getnow" onClick={e => setModalIsOpen(true)}>
                                    Get Now
                                </button>
                            </div>
                            <div className="Modal">
                                {/* {userData.user.id ? userData.user.username : ''} */}
                                <GetBookModal userId={userData.user.id ? userData.user.id : ""} bookId={id} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                            </div>
                        </>
                    ) : (
                        <div className="button-flex get-btn-unauthenticated">Please log in to get this book.</div>
                    )}
                    </>) :
                    (<div className="button-flex get-btn-unauthenticated">This book is already ordered by someone. Will be available to order {returnDate? (<>on {returnDate}</>): (<>in 1-2 weeks.</>)}</div>)}

            </div>
            <Footer />

        </>
    )
}

export default BookPage
